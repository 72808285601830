import styles from "./message.user.module.css"
import gptLogo from "./gpt-logo.jpg"

export default function MessageUser(props) {
    return (
        <div className={styles.message}>
            {props.children}</div>

    )

}