/* eslint-disable no-unused-expressions */
import Typewriter from 'typewriter-effect/dist/core';

import React, { useEffect, useState } from "react";
import styles from "./main.module.css"
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { positions } from '@mui/system';
import MessageBot from "./MessageBot";
import MessageUser from "./MessageUser";
import { Box, Chip, Modal, Skeleton } from "@mui/material";
import axios, { all } from 'axios';
import { getToken } from "./script.js";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useDetectAdBlock } from "adblock-detect-react";
import ShareIcon from '@mui/icons-material/Share';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import bots from "./bots.json"
const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};


export default function Main() {
    const [isNav, setNav] = useState(false)
    const [style, setStyle] = useState(bots[0])
    const [text, setText] = useState("")
    const [isStart, setStart] = useState(true)
    const [startPhrases, setStartPhrases] = useState([
        "Помоги написать сочинение",
        "Помоги решить задачу",
        "Когда я найду свою любовь? Прогноз Овен 33 года",
        "Совместимость девы и близнецов",
        "Таро на завтра для Водолея"
    ])
    const [disabled, setDisabled] = useState();
    const [messages, setMessages] = useState([
        {
            from: 1,
            message: "Привет! Задай мне вопрос и я постараюсь ответить"
        }
    ])
    useEffect(() => {
        window.yaContextCb.push(() => {
            window.Ya.Context.AdvManager.render({
                "blockId": "R-A-2931046-6",
                "renderTo": "yandex_rtb_R-A-2931046-6"
            })
        })
    }, []);
    async function copy(text) {
        setModal(true)
        navigator.clipboard.writeText(text)
        await new Promise(res => setTimeout(res, 300));

        setModal(false)

    }
    const [modal, setModal] = useState(false)

    async function getMessage() {
        setDisabled(true)

        const newArr = messages;

        newArr.push({ from: 2, message: text })
        newArr.push({
            from: 1,
            message: null
        });

        setMessages(newArr)

        const agent = (await axios.get("https://api.dev-guru.site/api/main/agent")).data;
        const token = getToken(agent)
        const typewriter = new Typewriter('#typewriter', { loop: true });

        typewriter.typeString(style.loading).start()
        try {
            console.log(text)
            const url = encodeURI(`https://api.dev-guru.site/api/main/gpt?token=${token}&message=${text}&agent=${agent}&style=${style.style}`)
            const response = await axios.get(url);
            const newState = messages.map(obj => {
                if (obj.message === null) {
                    return { ...obj, message: response.data };
                }
                return obj;
            });
            setText("")
            console.log(newState)
            setMessages(newState)
        } catch (e) {
            const newState = messages.map(obj => {
                if (obj.message === null) {
                    return { ...obj, message: "Упс... Что-то пошло не так. Попробуйте еще раз" };
                }
                return obj;
            });
            setMessages(newState)
            setDisabled(false)

        } console.log(messages)
        setDisabled(false)

    }
    return (<div className={styles.container}>
        <Modal keepMounted open={modal}>
            <Box sx={{ ...modalStyle, textAlign: "center" }}>
                <span>Скопировано в буфер обмена</span>
            </Box>
        </Modal>


        <h1>ChatGPT на русском языке <span style={{ backgroundColor: "#2DB59E", color: "#fff" }}>бесплатно</span></h1>
        {/* <span onClick={() => copy("https://dev-guru.site")} className={styles.link}>https://dev-guru.site <ShareIcon sx={{ width: 18, height: 18 }} /></span> */}

        {
            messages.map((x, index) => {
                if (x.from === 1) {

                    return (
                        <React.Fragment>
                            <MessageBot onClick={() => copy(x.message)} key={x.index}>
                                <span>{x.message === null ? <div>
                                    <span id="typewriter"></span>


                                </div> : x.message}</span>

                                {/* <div style={{ height: 125, width: 125 }} id="yandex_rtb_R-A-2931046-5"></div>
                                {pushAdd()} */}
                            </MessageBot>
                            
                        </React.Fragment>
                    )
                }
                else {
                    return (
                        <MessageUser key={x.index}><span>{x.message}</span></MessageUser>

                    )
                }

            }
            )
            
        }
        <div style={{ display: !disabled  ? "flex" : "none", gap: 8, flexWrap: "wrap" }}>
                                {style.questions.map((x) =>
                                    <Chip onClick={(e) => setText(e.target.innerText)} icon={<AddCircleIcon fontSize="small" />} size="small" sx={{ width: "fit-content", cursor: "pointer" }} label={x}></Chip>

                                )}


                            </div>
        <div style={{zIndex:-1}} id="yandex_rtb_R-A-2931046-6"></div>
        <div className={styles.botStyle} onClick={() => setNav(!isNav)}>
            <span>{style.title}</span>
            {isNav &&
                <div className={styles.botStyleBody}>
                    {
                        bots.map((x) =>
                            <span onClick={() => setStyle(x)}>{x.title}</span>
                        )
                    }

                </div>

            }


        </div>
        <div className={styles.inputBlock} >
            <input value={disabled ? "Загрузка..." : text} onChange={(e) => setText(e.target.value)} placeholder="Задайте вопрос"></input>
            <div style={{ pointerEvents: disabled ? "none" : "all", opacity: disabled ? 0.5 : 1 }} className={styles.inputButton} onClick={() => getMessage()} >
                <SendIcon>
                </SendIcon>
            </div>

            <div></div>
        </div>

    </div>);
}