import styles from "./message.bot.module.css"
import gptLogo from "./gpt-logo.jpg"

export default function MessageBot(props) {
    return (
        <div onClick={props.onClick} className={styles.message}><img className={styles.gptLogo} src={gptLogo}></img>
            {props.children}</div>

    )

}