/* eslint-disable no-unused-expressions */
export function getToken(agent) {
    var  h, f;
    h = Math.round(1E11 * Math.random()) + "";
    f = function () {
        for (var p = [], q = 0; 64 > q;) p[q] = 0 | 4294967296 * Math.sin(++q % Math.PI);

        return function (t) {
            var v, y, H, ea = [v = 1732584193, y = 4023233417, ~v, ~y],
                Z = [],
                A = unescape(encodeURI(t)) + "\u0080",
                z = A.length;
            t = --z / 4 + 2 | 15;
            for (Z[--t] = 8 * z; ~z;) Z[z >> 2] |= A.charCodeAt(z) << 8 * z--;
            for (q = A = 0; q < t; q += 16) {
                for (z = ea; 64 > A; z = [H = z[3], v + ((H = z[0] + [v & y | ~v & H, H & v | ~H & y, v ^ y ^ H, y ^ (v | ~H)][z = A >> 4] + p[A] + ~~Z[q | [A, 5 * A + 1, 3 * A + 5, 7 * A][z] & 15]) << (z = [7, 12, 17, 22, 5, 9, 14, 20, 4, 11, 16, 23, 6, 10, 15, 21][4 * z + A++ % 4]) | H >>> -z), v, y]) v = z[1] | 0, y = z[2];
                for (A = 4; A;) ea[--A] += z[A]
            }
            for (t = ""; 32 > A;) t += (ea[A >> 3] >> 4 * (1 ^ A++) & 15).toString(16);
            return t.split("").reverse().join("")
        }
    }();
    const token = "tryit-" + h + "-" + f(agent + f(agent + f(agent + h + "x")));
    return token;
}